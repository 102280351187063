<template>
  <v-navigation-drawer
    :value="isFilterProductSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="(val) => $emit('update:is-product-filter-sidebar-active', val)"
  >
    <v-sheet
      :color="isDark ? '#363351' : '#fafafa'"
      class="pa-3 d-flex align-center justify-space-between"
    >
      <div class="d-flex align-center">
        <v-avatar
          size="48"

          color="secondary"
        >
          <v-icon
            size="24"
            class="white--text"
          >
            {{ icons.mdiFilter }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          Filtreleme
        </div>
      </div>
      <v-btn
        v-if="false"
        icon
        x-small

        @click="$emit('update:is-product-filter-sidebar-active',false)"
      >
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <div>
      <v-text-field
        v-model="filter.id"
        label="Sıra"
        class="rounded-0"
        filled
        hide-details
      />
      <v-select
        v-model="filter.aktif"
        label="Durum"
        :items="$store.state.tanimlar.durum"
        class="rounded-0"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        clearable
        hide-details
      ></v-select>
      <v-text-field
        v-model="filter.urun_kodu"
        label="Ürün Kodu"
        class="rounded-0"
        filled
        hide-details
      />
      <v-text-field
        v-model="filter.baslik"
        label="Başlık"
        class="rounded-0"
        filled
        hide-details
      />

      <v-select
        v-model="filter.plant"
        label="Fabrika"

        class="rounded-0"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        clearable
        hide-details
      ></v-select>
    </div>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn
          block
          text
          small
          rounded
          plain
          class="mb-3"
          @click="filterClear"
        >
          Temizle
        </v-btn>

        <v-btn
          x-large
          rounded
          block
          class="text-capitalize"
          color="secondary"
          @click="filtering"
        >
          Tamam
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiFilter, mdiFilterOff } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {
  model: {
    prop: 'isFilterProductSidebarActive',
    event: 'update:is-product-filter-sidebar-active',
  },
  props: {
    isFilterProductSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isDark } = useAppConfig()
    const filter = ref({
      id: '',
      aktif: '',
      baslik: '',
      urun_kodu: '',
      plant: '',
    })
    const search = ref()
    const isLoading = ref(false)
    const items = ref([])
    const isFilter = ref(0)

    const filtering = () => {
      emit('filter-data', filter.value)
      emit('update:is-product-filter-sidebar-active', false)
    }
    const filterClear = () => {
      emit('filter-data', {})
      emit('update:is-product-filter-sidebar-active', false)
      filter.value = {
        id: '',
        aktif: '',
        baslik: '',
        urun_kodu: '',
        plant: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filitre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }

    watch(
      filter,
      item => {
        let i = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(item)) {
          if (value) {
            i += 1
          }
        }
        isFilter.value = i
      },
      { deep: true },
    )

    return {
      items,
      isFilter,
      isLoading,
      isDark,
      filter,
      filtering,
      filterClear,
      search,

      icons: {
        mdiFilter,
        mdiFilterOff,
        mdiClose,
      },
    }
  },
}
</script>
